<script type="text/ecmascript-6">

import DashboardLayout from '../layouts/Dashboard.vue'
import kycService from '../services/kyc'
import userService from '../services/user'

export default {
    
    components: {DashboardLayout},
    data() {

        return {

            kyc: null,
            error: null,
            isLoading: false,
            editUser: false
        }
    },
    created() {

        this.loadKyc()
    },
    methods: {
        async loadKyc() {

            let response = await kycService.get(this.kycId)
            this.kyc = response.data
        },
        async getFile1() {

            let response = await kycService.getKycFile1(this.kycId)
            let file = response.data
            this.openFile(file.content, file.extension)
        },
        async getFile2() {

            let response = await kycService.getKycFile2(this.kycId)
            let file = response.data
            this.openFile(file.content, file.extension)
        },
        async openFile(content, extension) {

            let a = document.createElement("a")
            document.body.appendChild(a);
            a.style = "display: none"

            let byteCharacters = atob(content)
            let byteNumbers = new Array(byteCharacters.length)
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i)
            }
            let byteArray = new Uint8Array(byteNumbers)
            let type = ''
            switch (extension.toLowerCase()) {

                case 'pdf':
                    type = 'application/pdf'
                    break
                case 'jpeg':
                case 'jpg':
                    type = 'image/jpeg'
                    break
                case 'png':
                    type = 'image/png'
                    break
                case 'gif':
                    type = 'image/gif'
                    break
            }
            let file = new Blob([byteArray], { type: type+';base64' })
            let fileURL = URL.createObjectURL(file)
            
            a.href = fileURL
            a.setAttribute('target', '_blank')
            a.click();
        },
        async validate() {

            this.error = null

            try {

                await kycService.validate(this.kycId, this.kyc.first_name, this.kyc.last_name, this.kyc.identity_type, this.kyc.number, this.kyc.delivery, this.kyc.country, this.kyc.user.nationality, this.kyc.user.residence_country, this.kyc.user.birthday)
                this.loadKyc()
            }
            catch (error) {

                this.error = error
            }
        },
        async refuse() {

            this.error = null
            
            try {

                await kycService.refuse(this.kycId)
                this.loadKyc()
            }
            catch (error) {

                this.error = error
            }
        },
        async reopen() {

            this.error = null
            
            try {

                await kycService.reopen(this.kycId)
                this.loadKyc()
            }
            catch (error) {

                this.error = error
            }
        },
        async getOCRInfos() {

            this.isLoading = true

            try {

                let infos = await kycService.getOCRInfos(this.kycId)
                infos = infos.data
                this.kyc.first_name = infos.firstName
                this.kyc.last_name = infos.lastName
                this.kyc.number = infos.documentNumber
                this.kyc.user.birthday = infos.birthDate
                this.kyc.user.nationality = infos.nationality
                this.kyc.country = infos.nationality
                this.kyc.user.residence_country = 'FR'
                this.kyc.delivery = infos.issueDate

                this.isLoading = false
            }
            catch (error) {

                alert('Error during Get OCR Infos')
            }
        },
        async updateUser() {

            await userService.update(this.kyc.user.id, this.kyc.user.last_name, this.kyc.user.first_name)

            this.editUser = false
        }
    },
    computed: {
        kycId() {

            return this.$route.params.kycId
        },
        canEdit() {

            return this.kyc && this.kyc.status === 'to_validate'
        },
        canReopen() {

            return this.kyc && this.kyc.status === 'treated' && this.kyc.is_validated == false
        },
        displayedStatus() {

            if (this.kyc == undefined) return ''
            if (this.kyc.status === 'to_validate') return this.kyc.status
            if (this.kyc.status === 'treated') return this.kyc.is_validated ? 'validated' : 'refused'

            return ''
        },
        displayOCRInfosButton() {

            return this.isLoading == false && this.kyc && this.kyc.file1 && this.kyc.status == 'to_validate' && (this.kyc.identity_type == 'identity_card' || this.kyc.identity_type == 'passport' || this.kyc.identity_type == 'driver_licence')
        },
        isUnderAge() {

            if (this.kyc.user.birthday && this.kyc.user.birthday.length == 10) {

                let birthday = new Date(this.kyc.user.birthday)
                let now = new Date()
                let age = now.getFullYear() - birthday.getFullYear()
                if (now.getMonth() < birthday.getMonth() || (now.getMonth() == birthday.getMonth() && now.getDate() < birthday.getDate())) age--
                
                return age < 18
            }

            return false
        },
        deliveryDiffYears() {

            if (this.kyc.delivery && this.kyc.delivery.length == 10) {

                let delivery = new Date(this.kyc.delivery)
                let now = new Date()
                let diff = now - delivery
                let diffDays = diff / (1000 * 60 * 60 * 24)
                
                return Math.floor(diffDays / 365)
            }

            return null
        },
        ageYearsAtDelivery() {

            if (this.kyc.delivery && this.kyc.delivery.length == 10 && this.kyc.user.birthday && this.kyc.user.birthday.length == 10) {

                let delivery = new Date(this.kyc.delivery)
                let birthday = new Date(this.kyc.user.birthday)
                let diff = delivery - birthday
                let diffDays = diff / (1000 * 60 * 60 * 24)
                
                return Math.floor(diffDays / 365)
            }

            return null
        },
        isFirstNameIsDifferent() {
            
            return this.kyc && this.kyc.user && this.kyc.user.first_name && this.kyc.first_name && this.kyc.first_name.toLowerCase() != this.kyc.user.first_name.toLowerCase()
        },
        isLastNameIsDifferent() {
    
            return this.kyc && this.kyc.user && this.kyc.user.last_name && this.kyc.last_name && this.kyc.last_name.toLowerCase() != this.kyc.user.last_name.toLowerCase()
        }
    },
}
</script>
<template>
    <dashboard-layout>
        <h2>Kyc Detail</h2>
        <h3>User</h3>
        <div class="custom-control custom-switch ml-2">
            <input type="checkbox" class="custom-control-input" v-model="editUser" id="EditUserSwitch">
            <label v-if="!editUser" class="custom-control-label" for="EditUserSwitch">Read mode</label>
            <label v-if="editUser" class="custom-control-label" for="EditUserSwitch">Edit mode</label>
        </div>
        <table v-if="kyc && kyc.user" class="table table-bordered">
            <tr>
                <th>First Name</th>
                <td>
                    <span v-if="!editUser">{{kyc.user.first_name}}</span>
                    <div v-else class="d-flex align-items-center">
                        <input type="text" v-model="kyc.user.first_name" class="form-control">
                        <button @click="updateUser()" class="btn btn-primary ml-2">Save</button>
                    </div>
                </td>
            </tr>
            <tr>
                <th>Last Name</th>
                <td>
                    <span v-if="!editUser">{{kyc.user.last_name}}</span>
                    <div v-else class="d-flex align-items-center">
                        <input type="text" v-model="kyc.user.last_name" class="form-control">
                        <button @click="updateUser()" class="btn btn-primary ml-2">Save</button>
                    </div>
                </td>
            </tr>
        </table>
        <h3>
            Kyc
            <button :disabled="!kyc || !kyc.file1" @click="getFile1" class="btn btn-info">Show file 1</button>
            <button :disabled="!kyc || !kyc.file2" @click="getFile2" class="btn btn-info ml-2">Show file 2</button>
            <button :disabled="!displayOCRInfosButton" @click="getOCRInfos" class="btn btn-info ml-2">
                Get OCR Infos
                <span v-if="isLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
            </button>
        </h3>
        <table v-if="kyc" class="table table-bordered">
            <tr>
                <th>Status</th>
                <td>{{displayedStatus}}</td>
            </tr>
            <tr>
                <th>First Name</th>
                <td>
                    <input :disabled="!canEdit" type="text" v-model="kyc.first_name">
                    <span class="alert alert-danger ml-2" v-if="isFirstNameIsDifferent">First name different than the one provided</span>
                </td>
            </tr>
            <tr>
                <th>Last Name</th>
                <td>
                    <input :disabled="!canEdit" type="text" v-model="kyc.last_name">
                    <span class="alert alert-danger ml-2" v-if="isLastNameIsDifferent">Last name different than the one provided</span>
                </td>
            </tr>
            <tr>
                <th>Type</th>
                <td>
                    <select :disabled="!canEdit" v-model="kyc.identity_type">
                        <option></option>
                        <option value="identity_card">identity_card</option>
                        <option value="passport">passport</option>
                        <option value="resident_permit">resident_permit</option>
                        <option value="driver_licence">driver_licence</option>
                    </select>
                </td>
            </tr>
            <tr>
                <th>Number</th>
                <td><input :disabled="!canEdit" type="text" v-model="kyc.number"></td>
            </tr>
            <tr>
                <th>Delivery Date</th>
                <td>
                    <input :disabled="!canEdit" type="text" v-model="kyc.delivery" placeholder="2020-11-27">
                    <span class="alert alert-danger ml-2" v-if="deliveryDiffYears && deliveryDiffYears >= 15">Is expired ({{ deliveryDiffYears }} years)</span>
                    <span class="alert alert-danger ml-2" v-if="deliveryDiffYears && ageYearsAtDelivery && deliveryDiffYears >= 10 && ageYearsAtDelivery < 18">Is expired ({{ deliveryDiffYears }} years but was {{ ageYearsAtDelivery }} at delivery)</span>
                </td>
            </tr>
            <tr>
                <th>Country</th>
                <td><input :disabled="!canEdit" type="text" v-model="kyc.country" placeholder="FR"></td>
            </tr>
            <tr>
                <th>Nationality</th>
                <td><input :disabled="!canEdit" type="text" v-model="kyc.user.nationality" placeholder="FR"></td>
            </tr>
            <tr>
                <th>Residency Country</th>
                <td><input :disabled="!canEdit" type="text" v-model="kyc.user.residence_country" placeholder="FR"></td>
            </tr>
            <tr>
                <th>Birthday</th>
                <td>
                    <input :disabled="!canEdit" type="text" v-model="kyc.user.birthday" placeholder="2020-11-27">
                    <span class="alert alert-danger ml-2" v-if="isUnderAge">-18</span>
                </td>
            </tr>
        </table>
        <div v-if="error" class="alert alert-danger">{{error}}</div>
        <button :disabled="!canEdit" @click='refuse' class="btn btn-danger">Refuse</button>
        <button :disabled="!canEdit" @click="validate" class="btn btn-success ml-4">Validate</button>
        <button :disabled="!canReopen" @click="reopen" class="btn btn-info ml-4">Re-open</button>
    </dashboard-layout>
</template>
