import axios from 'axios'

export default {

    reopen(orderPublicId) {

        let path = '/api/bo/orders/' + orderPublicId + '/counterOffers'

        return axios.patch(path);
    }
}
