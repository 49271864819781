import axios from 'axios'

export default {

    list(status) {

        const path = '/api/bo/brandCampaigns?status[]=' + status

        return axios.get(path);
    },
    get(brandCampaignId) {

        const path = '/api/bo/brandCampaigns/' + brandCampaignId

        return axios.get(path);
    },
    importCode(codes, brandCampaignId) {

        const path = '/api/bo/brandCampaigns/' + brandCampaignId + '/codes'

        var data = {

            codes: codes
        } 

        return axios.post(path, data)
    },
    importSKUs(skus, brandCampaignId) {

        const path = '/api/bo/brandCampaigns/' + brandCampaignId + '/skus'

        let data = {

            skus: skus
        } 

        return axios.post(path, data)
    },
    create(merchantId, publicId, name, status, amount, startDate, endDate, brandId, categoryIds, legalText, canRenewPreauth, isCashRefund, minResellPrice) {
        
        const path = '/api/bo/brandCampaigns'

        let data = {
            merchantId: merchantId,
            publicId: publicId,
            name: name,
            status: status,
            amount: amount,
            startDate: startDate,
            endDate: endDate,
            brandId: brandId,
            categoryIds: categoryIds,
            legalText: legalText,
            canRenewPreauth: canRenewPreauth,
            isCashRefund: isCashRefund,
            minResellPrice: minResellPrice
        }

        return axios.post(path, data)
    },
    update(id, merchantId, publicId, name, status, amount, startDate, endDate, brandId, categoryIds, legalText, canRenewPreauth, isCashRefund, minResellPrice) {
        
        const path = '/api/bo/brandCampaigns/' + id

        let data = {
            merchantId: merchantId,
            publicId: publicId,
            name: name,
            status: status,
            amount: amount,
            startDate: startDate,
            endDate: endDate,
            brandId: brandId,
            categoryIds: categoryIds,
            legalText: legalText,
            canRenewPreauth: canRenewPreauth,
            isCashRefund: isCashRefund,
            minResellPrice: minResellPrice
        }

        return axios.patch(path, data)
    }
}
