<script type="text/ecmascript-6">

import DashboardLayout from '../layouts/Dashboard.vue'
import orderServices from '../services/order'
import userMixin from '@/mixins/user'
import StandardModal from '../components/StandardModal.vue'

export default {
    
    components: {DashboardLayout, StandardModal},
    mixins: [userMixin],
    data() {

        return {
            order: null,
            isLoading: false,
            displayResendNewCode: false,
            newCodeSent: false
        }
    },
    methods: {
        async loadOrderDetails() {

            let orderId = this.$route.params.orderId
            let response = await orderServices.getOrderDetails(orderId, true)
            this.order = response.data
            this.order.updated = 0
        },
        async sendNewCode() {

            this.isLoading = true
            this.displayResendNewCode = false

            await orderServices.sendNewMerchantBrandCampaignCode(this.order.public_id)
            this.newCodeSent = true
            await this.loadOrderDetails()
            this.isLoading = false
        },
        async getMerchantBrandCampaignCode(codeId) {

            this.isLoading = true
            
            let response = await orderServices.getMerchantBrandCampaignCode(this.order.public_id, codeId)
            for (let i = 0; i < this.order.brand_campaign_codes.length; i++) {

                if (this.order.brand_campaign_codes[i].id == codeId) {

                    this.order.brand_campaign_codes[i].status = response.data.status
                    this.$forceUpdate()
                    break
                }
            }

            this.isLoading = false
        },
    },
    created() {

        this.loadOrderDetails()
    },
    computed: {
        codes() {

            if (this.order == undefined || this.order.brand_campaign_codes == undefined) return []

            return this.order.brand_campaign_codes.slice().sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        },
        canSendNewCode() {

            if (this.newCodeSent) return false;

            if ([2,3,4,5,10].includes(this.order.status.id) && this.order.has_brand_campaign_code == true) {

                return true
            }

            return false
        },
    },
}
</script>
<template>
    <dashboard-layout>
        <div v-if="order">
            <h2>{{ order.public_id }}</h2>
            <h4>{{ order.brand_campaign.name + ' - ' + (order.brand_campaign.bonus_amount / 100) + ' €' }}</h4>
            <button v-if="canSendNewCode" class="btn btn-info my-3" @click="displayResendNewCode = true">Send new code</button>
            <table class="table table-bordered">
                <thead>
                    <th>ID</th>
                    <th>Created At</th>
                    <th>Current Code</th>
                    <th>Status</th>
                </thead>
                <tr v-for="code in codes" :key="code.brand_campaign_code">
                    <td>{{ code.id }}</td>
                    <td>{{ code.created_at | dateTime }}</td>
                    <td>
                        <i v-if="code.is_current_code" class="bi bi-check text-success"></i>
                        <i v-if="!code.is_current_code" class="bi bi-x text-danger"></i>
                    </td>
                    <td>
                        <span v-if="code.status">{{ code.status }}</span>
                        <button v-else class="btn btn-info" @click="getMerchantBrandCampaignCode(code.id)">Get Status</button>
                    </td>
                </tr>
            </table>
            <standard-modal v-if="displayResendNewCode" :closeLabel="'Annuler'" :title="'Resend New Code'" :hasOkButton="true" @ok="sendNewCode(order)" @close="displayResendNewCode = false">
                <template v-slot:body>
                    <div class="modal-body">
                        <p>Are you sure to send a new code for {{ order.public_id }} / {{ order.user.email }} ?</p>
                    </div>
                </template>
            </standard-modal>
        </div>
    </dashboard-layout>
</template>
