<script type="text/ecmascript-6">

import DashboardLayout from '../layouts/Dashboard.vue'
import brandCampaignServices from '../services/brandCampaign'

export default {
    components: {DashboardLayout},
    data() {
        return {
            
            brandCampaign: null,
            csvFile: [],
            isLoading: false,
            error: '',
            mode: 'read',
            updateMode: false,
            merchantsToSelect: [
                {
                    id: 6211121839,
                    name: 'Fnac',
                    suffix: 'brand_campaign_FNAC',
                    legalText: ' valable jusqu\'au DD/MM/YYYY à utiliser pour acheter un <a target="_blank" href="XXXXX">XXXXX éligible</a> sur fnac.com. Non cumulable avec un autre code promo.',
                    isCashRefund: false,
                    hasSKUs: false
                },
                {
                    id: 3919794380,
                    name: 'Fnac Reprise',
                    suffix: 'brand_campaign_FNACR',
                    legalText: ' valable jusqu\'au DD/MM/YYYY à utiliser pour acheter un <a target="_blank" href="XXXXX">XXXXX éligible</a> sur fnac.com. Non cumulable avec un autre code promo.',
                    isCashRefund: false,
                    hasSKUs: true
                },
                {
                    id: 7764956693,
                    name: 'Darty Reprise',
                    suffix: 'brand_campaign_DARTYR',
                    legalText: '',
                    isCashRefund: true,
                    hasSKUs: true
                },
            ],
            merchantSelected: null,
            statuses: ['running', 'created', 'done'],
            brands: [
                {
                    id: 5,
                    name: 'Apple'
                },
                {
                    id: 54,
                    name: 'Samsung'
                },
                {
                    id: 62,
                    name: 'Xiaomi'
                },
                {
                    id: 24,
                    name: 'Google'
                },
                {
                    id: 25,
                    name: 'Honor'
                },
                {
                    id: 68,
                    name: 'Nintendo'
                }
            ],
            categoriesToSelect: [
                {
                    id: 3,
                    name: 'Smartphones'
                },
                {
                    id: 6,
                    name: 'Tablettes'
                },
                {
                    id: 2,
                    name: 'Ordinateurs portables'
                },
                {
                    id: 7,
                    name: 'Consoles'
                }
            ],
            categoriesSelected: [],
            errors: [],
            SKUsToImport: ''
        }
    },
    methods: {
        async loadBrandCampaign() {

            this.csvFile = []
            let brandCampaignId = this.$route.params.brandCampaignId
            let response = await brandCampaignServices.get(brandCampaignId)
            this.brandCampaign = response.data
            this.merchantSelected = this.merchantsToSelect.find(merchant => merchant.id == this.brandCampaign.merchant_id)
            this.categoriesSelected = this.categoriesToSelect.filter(category => this.brandCampaign.categories.map(category => category.id).includes(category.id))
        },
        initEmptyBrandCampaign() {

            let today = new Date().toISOString().split('T')[0]

            this.brandCampaign = {
                public_id: '',
                name: '',
                status: '',
                bonus_amount: 0,
                start_date: today,
                end_date: today,
                brand: null,
                categories: [],
                merchant_id: null,
                color: '',
                legal_application_label: '',
                can_renew_preauth: false,
                is_cash_refund: false,
                countSKUs: 0,
                countCodes: 0,
                min_resale_price: 100
            }
        },
        handleFileUpload(event) {
            const file = event.target.files[0];

            const reader = new FileReader();

            return new Promise((resolve) => {
                reader.onload = () => {
                    const lines = reader.result.split('\n');
   
                    const codes = lines.map(line => line.split(';')[0].trim());
                    codes.shift();
                    this.csvFile = codes;

                    resolve(this.csvFile);
                };

                reader.readAsText(file);
            });
        },
        async importCodes() {

            this.isLoading = true
            this.error = ''

            try {

                await brandCampaignServices.importCode(this.csvFile, this.brandCampaign.id)
            }
            catch (error) {

                this.isLoading = false
                this.error = error.message
            }

            this.$router.push({name: 'brandCampaign', params: {brandCampaignId: this.brandCampaign.id}})
            this.$router.go() 
        },
        async importSKUs() {

            this.isLoading = true
            this.error = ''
            let SKUs = this.SKUsToImport.split('\n')

            try {

                await brandCampaignServices.importSKUs(SKUs, this.brandCampaign.id)
            }
            catch (error) {

                this.isLoading = false
                this.error = error.message
            }

            this.$router.push({name: 'brandCampaign', params: {brandCampaignId: this.brandCampaign.id}})
            this.$router.go()
        },
        async create() {

            let errors = this.checkBrandCampaign()
            if (errors.length > 0) {

                this.errors = errors
                return
            }

            this.isLoading = true
            let response = await brandCampaignServices.create(
                this.brandCampaign.merchant_id,
                this.brandCampaign.public_id, 
                this.brandCampaign.name,
                this.brandCampaign.status,
                this.brandCampaign.bonus_amount,
                this.brandCampaign.start_date,
                this.brandCampaign.end_date,
                this.brandCampaign.brand.id,
                this.categoriesSelected.map(category => category.id),
                this.brandCampaign.legal_application_label,
                this.brandCampaign.can_renew_preauth,
                this.brandCampaign.is_cash_refund,
                this.brandCampaign.min_resale_price
            )

            this.$router.push({name: 'brandCampaign', params: {brandCampaignId: response.data.id}})
            this.$router.go()
        },
        async update() {

            let errors = this.checkBrandCampaign()
            if (errors.length > 0) {

                this.errors = errors
                return
            }

            this.isLoading = true
            let response = await brandCampaignServices.update(
                this.brandCampaign.id,
                this.brandCampaign.merchant_id,
                this.brandCampaign.public_id, 
                this.brandCampaign.name,
                this.brandCampaign.status,
                this.brandCampaign.bonus_amount,
                this.brandCampaign.start_date,
                this.brandCampaign.end_date,
                this.brandCampaign.brand.id,
                this.categoriesSelected.map(category => category.id),
                this.brandCampaign.legal_application_label,
                this.brandCampaign.can_renew_preauth,
                this.brandCampaign.is_cash_refund,
                this.brandCampaign.min_resale_price
            )

            this.$router.push({name: 'brandCampaign', params: {brandCampaignId: response.data.id}})
            this.$router.go()
        },
        checkBrandCampaign() {

            let errors = []
            
            if (this.brandCampaign.merchant_id == null || this.brandCampaign.merchant_id == '') {

                errors.push('Merchant Id is required')
            }

            if (this.brandCampaign.public_id == null || this.brandCampaign.public_id.length < 20) {

                errors.push('Public Id is required')
            }

            if (this.brandCampaign.name == null || this.brandCampaign.name.length < 5) {

                errors.push('Name is required')
            }

            if (this.brandCampaign.status == null || this.brandCampaign.status == '') {

                errors.push('Status is required')
            }

            if (this.brandCampaign.bonus_amount == null) {

                errors.push('Amount is required')
            }

            if (this.brandCampaign.start_date == null || this.brandCampaign.start_date == '') {

                errors.push('Start Date is required')
            }

            if (this.brandCampaign.end_date == null || this.brandCampaign.end_date == '' || this.brandCampaign.end_date == new Date().toLocaleDateString()) {

                errors.push('End Date is required')
            }

            if (this.brandCampaign.brand == null) {

                errors.push('Brand is required')
            }

            if (this.categoriesSelected.length <= 0) {

                errors.push('Categories is required')
            }

            if (this.brandCampaign.legal_application_label == null || this.brandCampaign.legal_application_label.length <= 0 || this.brandCampaign.legal_application_label.includes('YYYY') || this.brandCampaign.legal_application_label.includes('XXXXX')) {

                errors.push('Legal Text is required')
            }

            return errors
        }
    },
    computed: {
        categories() {

            let categories = ''
            
            if (this.brandCampaign == undefined) return categories
            
            let isFirst = true
            for (let i = 0; i < this.brandCampaign.categories.length; i++) {

                if (isFirst) 
                    categories = this.brandCampaign.categories[i].name
                else
                    categories = categories + ' ,' + this.brandCampaign.categories[i].name

                isFirst = false
            }

            return categories
        },
        isModeRead() {
            
            return this.mode == 'read'
        },
        isModeUpdate() {

            return this.mode == 'update'
        },
        isModeCreate() {

            return this.mode == 'create'
        }
    },
    created() {

        if (this.$route.params.brandCampaignId == 'new') {

            this.mode = 'create'
            this.initEmptyBrandCampaign()
        }
        else {

            this.loadBrandCampaign()
        }
    },
    watch: {
        updateMode() {

            if (this.updateMode) {

                this.mode = 'update'
            }
            else {

                this.mode = 'read'
            }
        },
        merchantSelected(newValue) {

            this.brandCampaign.merchant_id = newValue.id
            if (this.brandCampaign.public_id == '') 
                this.brandCampaign.public_id = newValue.suffix

            if (this.brandCampaign.legal_application_label == '')
                this.brandCampaign.legal_application_label = newValue.legalText

            this.brandCampaign.is_cash_refund = newValue.isCashRefund
        }
    }
}
</script>
<template>
    <dashboard-layout>
        <div v-if="brandCampaign != undefined">
            <h2 v-if="isModeCreate">NEW</h2>
            <h2 v-else>{{brandCampaign.public_id}}</h2>
            <div v-if="!isModeCreate" class="custom-control custom-switch ml-2">
                <input v-model="updateMode" type="checkbox" id="updateSwitch" class="custom-control-input">
                <label v-if="isModeUpdate" for="updateSwitch" class="custom-control-label">Update mode</label>
                <label v-else for="updateSwitch" class="custom-control-label">read mode</label>
            </div>
            <table class="table table-bordered col-md-10 mt-3">
                <tbody>
                    <tr>
                        <th>Merchant</th>
                        <td>
                            <span v-if="isModeRead">{{brandCampaign.merchant_id}}</span>
                            <select v-else v-model="merchantSelected" class="form-control">
                                <option :key="merchantToSelect.id" v-for="merchantToSelect in merchantsToSelect" :value="merchantToSelect">{{merchantToSelect.name}}</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th>Public ID</th>
                        <td>
                            <span v-if="isModeRead">{{brandCampaign.public_id}}</span>
                            <input v-else v-model="brandCampaign.public_id" type="text" class="form-control">
                        </td>
                    </tr>
                    <tr>
                        <th>Name</th>
                        <td>
                            <span v-if="isModeRead">{{brandCampaign.name}}</span>
                            <input v-else v-model="brandCampaign.name" type="text" class="form-control">
                        </td>
                    </tr>
                    <tr>
                        <th>Status</th>
                        <td>
                            <span v-if="isModeRead">{{brandCampaign.status}}</span>
                            <select v-else v-model="brandCampaign.status" class="form-control">
                                <option :key="status" v-for="status in statuses" :value="status">{{status}}</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th>Amount</th>
                        <td>
                            <span v-if="isModeRead">{{brandCampaign.bonus_amount | moneyInteger}}</span>
                            <input v-else v-model="brandCampaign.bonus_amount" type="number" class="form-control">
                        </td>
                    </tr>
                    <tr>
                        <th>Start Date</th>
                        <td>
                            <span v-if="isModeRead">{{brandCampaign.start_date}}</span>
                            <input v-else v-model="brandCampaign.start_date" type="text" class="form-control">
                        </td>
                    </tr>
                    <tr>
                        <th>End Date</th>
                        <td>
                            <span v-if="isModeRead">{{brandCampaign.end_date}}</span>
                            <input v-else v-model="brandCampaign.end_date" type="text" class="form-control">
                        </td>
                    </tr>
                    <tr>
                        <th>Brand</th>
                        <td>
                            <span v-if="isModeRead">{{brandCampaign.brand.name}}</span>
                            <select v-else v-model="brandCampaign.brand" class="form-control">
                                <option :key="brand.id" v-for="brand in brands" :value="brand">{{brand.name}}</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th>Categories</th>
                        <td>
                            <span v-if="isModeRead">{{categories}}</span>
                            <div v-else class="form-check" v-for="categoryToSelect in categoriesToSelect" :key="categoryToSelect.id">
                                <input class="form-check-input" type="checkbox" v-model="categoriesSelected" :value="categoryToSelect">{{ categoryToSelect.name }}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>Legal Text</th>
                        <td>
                            <span v-if="isModeRead">{{ brandCampaign.legal_application_label }}</span>
                            <textarea v-else v-model="brandCampaign.legal_application_label" type="text" rows="5" class="form-control"></textarea>
                        </td>
                    </tr>
                    <tr>
                        <th>Can renew preauth</th>
                        <td>
                            <span v-if="isModeRead">{{ brandCampaign.can_renew_preauth }}</span>
                            <input v-else v-model="brandCampaign.can_renew_preauth" type="checkbox">
                        </td>
                    </tr>
                    <tr>
                        <th>Is Cash Refund</th>
                        <td>
                            <span v-if="isModeRead">{{ brandCampaign.is_cash_refund }}</span>
                            <input v-else v-model="brandCampaign.is_cash_refund" type="checkbox">
                        </td>
                    </tr>
                    <tr>
                        <th>Min resell price</th>
                        <td>
                            <span v-if="isModeRead">{{ brandCampaign.min_resale_price | moneyInteger }}</span>
                            <input v-else v-model="brandCampaign.min_resale_price" type="number">
                        </td>
                    </tr>
                    <tr>
                        <th>Nbr SKUs</th>
                        <td>
                            {{brandCampaign.countSKUs}}
                            <div v-if="isModeUpdate && merchantSelected && merchantSelected.hasSKUs">
                                <textarea class="form-control" v-model="SKUsToImport"></textarea>
                                <button @click="importSKUs" class="btn btn-success mt-2">Import SKUs 
                                    <span v-if="isLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                </button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>Nbr Edited Codes</th>
                        <td>
                            {{brandCampaign.countCodes}}
                            <div v-if="isModeUpdate && brandCampaign.countCodes == 0 && brandCampaign.is_cash_refund == false" class="mt-2">
                                <input type="file" class="mb-4" @change="handleFileUpload">
                                <div v-if="error != ''" class="alert alert-danger">{{ error }}</div>
                                <button :disabled="csvFile.length < 1 || isLoading" @click="importCodes" class="btn btn-success ">Import codes 
                                    <span v-if="isLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <button :disabled="isLoading" v-if="isModeCreate" class="btn btn-info" @click="create()">Create</button>
            <button :disabled="isLoading" v-if="isModeUpdate" class="btn btn-info" @click="update()">Update</button>
            <div v-for="error in errors" :key="error" class="alert alert-danger mt-2">{{ error }}</div>
        </div>
    </dashboard-layout>
</template>